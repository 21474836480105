$container-width: 1340px;

/* COLORS */

$color-black: #000;
$color-white: #fff;
$color-blue: #00c3ec;
$color-dark-blue: #00a3c5;
$color-blue-transparent: rgba(76, 165, 188, 0.79);

/* MEDIA QUERIES */

$desktop-s:  unquote("max-width: 1260px");
$desktop-md: unquote("max-width: 1340px");
$tablet:     unquote("max-width: 1023px");
$mobile:     unquote("max-width: 767px");

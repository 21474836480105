.why {
  position: relative;
  padding-top: 40px;
  @media ($desktop-s) {
    padding-top: 50px;
  }
  @media ($tablet) {
    padding-top: 74px;
    padding-bottom: 26px;
  }
  @media ($mobile) {
    padding-top: 35px;
    padding-bottom: 0;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 24px;
    width: 100%;
    @media ($desktop-s) {
      background-size: 36%;
    }
    @media ($tablet) {
      padding-left: 22px;
      padding-right: 22px;
    }
    @media ($mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__woman {
    position: absolute;
    width: 43%;
    top: 83px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    margin: auto;
    max-width: 561px;
    @media ($tablet) {
      width: 32%;
      top: 109px;
    }
    @media ($mobile) {
      display: block;
      position: static;
      width: 79.5%;
      margin: 0 auto;
      max-width: 351px;
    }

  }
  &__title {
    margin-bottom: 43px;
    @media ($tablet) {
      margin-bottom: 48px;
    }
    @media ($mobile) {
      margin-bottom: 19px;
    }
  }
  &__col {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 377px;
    padding-top: 75px;
    padding-bottom: 151px;
    z-index: 1;
    @media ($desktop-s) {
      padding-bottom: 83px;
    }
    @media ($tablet) {
      padding-top: 0;
      padding-bottom: 0;
      width: 329px;
      &:last-child {
        width: 260px;
      }
    }
    @media ($mobile) {
      width: 100%;
      max-width: 290px;
      &:last-child {
        width: 100%;
      }
    }

  }
  &__item {
    display: flex;
    margin-bottom: 54px;
    svg {
      fill: none;
    }
    &--tab-mini {
      width: 350px;

    }
    @media ($tablet) {
      margin-bottom: 51px;
      &:last-child {
        margin-bottom: 0;
      }
      &--tab-mini {
        width: 260px;
      }
    }
    @media ($mobile) {
      width: 100%;
      max-width: 290px;
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 28px;
      }
      &--tab-mini {
        width: 100%;
      }
    }
  }

  &__item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 76px;
    height: 76px;
    background-color: #ECECEC;
    border-radius: 50%;
    border: 1px solid #E5E2E2;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.17);
    margin-right: 16px;
    @media ($tablet) {
      width: 51px;
      height: 51px;
      svg {
        width: 74%;
      }
    }
    @media ($mobile) {
      margin-right: 14px;
    }
  }

  &__item-title {
    font-weight: bold;
    line-height: 24px;
    font-size: 18px;
    color: #3F4447;
    margin-top: 0;
    margin-bottom: 6px;
    @media ($tablet) {
      font-size: 16px;
      line-height: 21px;
      margin-top: -3px;
      margin-bottom: 2px;
    }
    @media ($mobile) {
      font-size: 14px;
      margin-top: 3px;
      margin-bottom: 0px;
    }
  }

  &__item-text {
    line-height: 22px;
    font-size: 16px;
    color: #5C5C5C;
    margin: 0;
    @media ($tablet) {
      font-size: 14px;
      line-height: 19px;
    }
    @media ($mobile) {
      font-size: 12px;
      line-height: 16px;
    }
  }

}

.separator {
  display: flex;
  justify-content: center;
  &.martop {
    @media ($tablet) {
      margin-top: -131px;
    }
  }
  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    //width: 36px;
    text-align: center;
    svg {
      margin-top: 10px;
      fill: none;
      margin-bottom: 10px;
    }
    &:before,
    &:after {
      content: "";
      width: 1px;
      height: 47px;
      background-color: #C4C4C4;
    }

  }
  @media ($mobile) {
    display: none;
  }


}

.double-title {

  display: flex;
  align-item: center;
  @media ($desktop-s) {
    flex-direction: column;
  }
  &--border {
    padding-bottom: 23px;
    position: relative;
    @media ($desktop-s) {
      flex-direction: row;
    }
    @media ($tablet) {
      padding-bottom: 23px;
    }
    @media ($mobile) {
      padding-bottom: 15px;
      margin-bottom: 13px;
    }
    .double-title__main {
      width: 36%;
      font-size: 34px;
      @media ($desktop-s) {
        font-size: 28px;
        width: 50%;
        border-right: 1px solid #009BA7;
        border-bottom: none;
      }
      @media ($tablet) {
        padding-right: 19px;
        width: 45%;
      }
      @media ($mobile) {
        font-size: 22px;
        padding-right: 10px;
        width: 39%;
      }

    }
    .double-title__secondary {
      font-size: 18px;
      line-height: 24px;
      @media ($desktop-s) {
        padding-left: 18px;
        padding-top: 0;
      }
      @media ($tablet) {
        font-size: 16px;
        line-height: 21px;
      }
      @media ($mobile) {
        font-size: 14px;
        padding-left: 10px;
        line-height: 18px;
      }
    }
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 7px;
      bottom: 0;
      left: 0;
      background: linear-gradient(79.53deg, #EBEBEB 1.47%, #CACACA 50.15%, #ECECEC 95.2%);
    }
  }
  &__main {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
    line-height: normal;
    font-size: 24px;
    width: 35%;
    text-transform: uppercase;
    color: #3F4447;
    font-family: 'Din Alternate';
    border-right: 1px solid #009BA7;
    padding-right: 20px;
    @media ($desktop-s) {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #009BA7;
    }
    @media ($tablet) {
      font-size: 20px;
      border-bottom: none;
      br {
        display: none;
      }
    }
  }
  &__secondary {
    line-height: normal;
    line-height: 20px;
    font-size: 14px;
    color: #808283;
    padding-left: 18px;
    @media ($desktop-s) {
      padding-left: 0;
      padding-top: 15px;
    }
    @media ($tablet) {
      line-height: 17px;
      font-size: 12px;
      padding-top: 3px;
    }
    @media ($mobile) {
      padding-top: 12px;
    }
  }
}

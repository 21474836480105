*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    font-size: 62.5%;
}

body {
    line-height: 1.5;
    &.no-overflow {
      overflow: hidden;
    }
    @media ($desktop-s) {
      overflow-x: hidden;
    }
}

address {
    font-style: normal;
}

button {
    cursor: pointer;
}

img {
    height: auto;
    max-width: 100%;
}

label {
    cursor: pointer;
    user-select: none;
}

textarea {
    resize: vertical;
}

ul {
    @include list-reset;
}

@include placeholder {
    text-overflow: ellipsis;
}

input[placeholder] {
    text-overflow:ellipsis;
}

input::-moz-placeholder {
    text-overflow:ellipsis;
}

input:-moz-placeholder {
    text-overflow:ellipsis;
}

input:-ms-input-placeholder {
    text-overflow:ellipsis;
}

:focus::-webkit-input-placeholder {
    color: transparent;
}

:focus::-moz-placeholder {
    color: transparent;
}

:focus:-moz-placeholder {
    color: transparent;
}

:focus:-ms-input-placeholder {
    color: transparent;
}

.responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.site-container {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-width;
    padding-left: 30px;
    padding-right: 30px;
    @media ($tablet) {
      padding-left: 22px;
      padding-right: 22px;
    }
    @media ($mobile) {
      padding-left: 15px;
      padding-right: 15px;
    }
}

.site-title {
  font-family: 'Din Alternate';
  text-align: center;
  color: #009BA7;
  font-weight: normal;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 38px;
  text-transform: uppercase;
  @media ($tablet) {
    font-size: 32px;
  }
  @media ($mobile) {
    font-size: 24px;
  }
}

.site-wrapper {
    display: flex;
    flex-direction: column;
    font-family: 'PT Sans', sans-serif;
    font-size: 1.6rem;
    min-height: 100vh;
    overflow-x: hidden;
}

.site-content {
    flex: 1 0 auto;
}

.blue {
  color: $color-blue;
}

.plus-link {
  display: inline-block;
  position: relative;
  padding-left: 52px;
  line-height: normal;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  color: $color-blue;
  &:before {
    content: "";
    position: absolute;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0idXJsKCNwYWludDBfcmFkaWFsKSIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IiMwMEMzRUMiLz4KPGxpbmUgeDE9IjIwLjUiIHkxPSIxNCIgeDI9IjIwLjUiIHkyPSIyNyIgc3Ryb2tlPSIjMDBDM0VDIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWwiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAuNSAyMC41KSByb3RhdGUoOTApIHNjYWxlKDE0LjUpIj4KPHN0b3Agc3RvcC1jb2xvcj0id2hpdGUiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRUFFQUVBIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==');
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;
    background-size: cover;

  }
  &:hover {
    &:before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0iIzAwQzNFQyIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IndoaXRlIi8+CjxsaW5lIHgxPSIyMC41IiB5MT0iMTQiIHgyPSIyMC41IiB5Mj0iMjciIHN0cm9rZT0id2hpdGUiLz4KPC9zdmc+Cg==');
    }
  }

}

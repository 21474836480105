.review-card {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  width: 380px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  @media ($desktop-s) {
    width: 330px;
  }
  @media ($tablet) {
    width: 352px;
  }
  @media ($mobile) {
    width: 100%;
  }

  &__content {
    flex-grow: 1;
    padding-top: 10px;
    padding-left: 17px;
    padding-right: 15px;
    padding-bottom: 64px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    @media ($tablet) {
      padding-left: 14px;
    }
    @media ($mobile) {
      padding-right: 12px;
    }
  }

  &__head {
    align-items: center;
    display: flex;
    margin-bottom: 16px;
  }

  &__image {
    border-radius: 50%;
    flex-shrink: 0;
    height: 75px;
    margin-right: 19px;
    width: 75px;

    // width: 100%;
    // max-height: 380px;
    // @media ($mobile) {
    //   width: 100%;
    //   max-height: 760px;
    //   margin: 0 auto;
    // }
  }

  &__name {
    margin: 0;
    font-size: 18px;
    color: #3F4447;
  }
  &__text {
    font-size: 16px;
    line-height: 21px;
    p {
      margin-top: 0;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media ($tablet) {
      line-height: normal;
      font-size: 14px;

    }
  }
  &__link {
    position: absolute;
    bottom: 20px;
    left: 17px;
    padding-left: 57px;
  }

  &__more {
    color: #00c3ec;
  }

}

.instagram {
  padding-top: 50px;
  padding-bottom: 100px;
  background: #f9f9f9;

  @media ($tablet) {
    padding-bottom: 60px;
  }

  @media ($mobile) {
    padding-top: 24px;
  }

  &__more {
    color: inherit;
  }
}

.instagram__header {
  position: relative;
  margin-bottom: 40px;

  @media ($mobile) {
    margin-bottom: 30px;
  }
}

.instagram__title {
  margin-bottom: 20px;
}

.instagram__link {
  align-items: center;
  background-color: $color-blue;
  border-radius: 5px;
  color: $color-white;
  display: flex;
  font-family: PT Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  height: 54px;
  justify-content: center;
  line-height: 23px;
  margin-left: auto;
  margin-right: auto;
  padding: 14px;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  vertical-align: middle;
  width: 184px;

  &:hover {
    background-color: $color-dark-blue;
    transition: background-color 0.5s ease-out;
  }

  svg {
    fill: white;
    margin-right: 12px;
  }

  @media ($tablet) {
    top: -10px;
  }

  @media ($mobile) {
    position: static;
    margin: 22px auto 0;
    max-width: 320px;
    width: 100%;
  }
}

.instagram__pictures-list {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: $color-white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media ($mobile) {
    font-size: 14px;
  }
  @media (max-width: 620px) {
    max-width: 320px;
    margin: 0 auto;
  }
}

.instagram__pictures-item {
  cursor: pointer;
  height: 240px;
  width: 240px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);

  @media ($desktop-s) {
    &:last-child {
      display: none;
    }
  }

  @media ($tablet) {
    height: 170px;
    width: 170px;
  }

  @media ($mobile) {
    height: 140px;
    width: 140px;
    margin-bottom: 12px;

    .instagram__pictures-item:active {
      .instagram__text-description {
        opacity: 1;
        transition: opacity 0.5s ease-out;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    .instagram__text-description {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }
}

.instagram__text-description {
  font: inherit;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $color-blue-transparent;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 20px;
}

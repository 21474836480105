.soon {
  background-color: #F9F9F9;
  padding-top: 70px;
  @media ($mobile) {
    padding-top: 22px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 1242px;
    padding-bottom: 45px;
    @media ($desktop-s) {
      max-width: 1000px;

    }
    @media ($tablet) {
      padding-bottom: 60px;
      max-width: 800px;
    }
    @media ($mobile) {
      flex-direction: column;
      padding-bottom: 0;
      max-width: 500px;
    }
  }
  &__title {
    margin-bottom: 40px;
    @media ($mobile) {
      margin-bottom: 25px;
    }
  }



}

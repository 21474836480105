.nav {
  &.menu-opened {
    transform: translateX(0);
  }

  @media ($desktop-s) {
    overflow-y: scroll;
    height: 100%;
    position: fixed;
    width: 320px;
    right: 0;
    transform: translateX(150%);
    top: 0;
    z-index: 11;
    background: rgba(76, 165, 188, 0.95);
    padding-left: 14px;
    padding-right: 15px;
    transition: all 0.3s;
  }
  @media ($mobile) {
    width: 100%;
  }

  &__list {
    display: flex;
    margin-left: -22px;
    margin-right: -22px;
    padding-bottom: 6px;
    @media ($desktop-s) {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
      padding-top: 20px;
      padding-bottom: 17px;
      border-bottom: 1px solid white;
    }
  }
  &__item {
    @media ($desktop-s) {
      margin-bottom: 7px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__link {
    font-size: 18px;
    padding-left: 22px;
    padding-right: 22px;
    color: #999999;
    text-decoration: none;
    &:hover {
      color: #009BA7;
    }
    @media ($desktop-s) {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      color: white;
      &:hover {
        color: white;
      }
    }
  }
  &__mob-top {
    display: none;
    padding-bottom: 17px;
    padding-top: 30px;
    border-bottom: 1px solid #FFFFFF;
    justify-content: space-between;
    padding-bottom: 9px;
    padding-top: 28px;
    @media ($desktop-s) {
      display: flex;
    }
  }
  &__mob-title {
    line-height: normal;
    font-size: 24px;
    text-transform: uppercase;
    font-family: 'Din Alternate';
    color: white;
  }
  &__mob-close {
    padding: 0;
    background: none;
    border: none;
    svg {
      fill: white;
    }
  }
  &__mob-info {
    display: none;
    padding-top: 22px;
    a {
      display: block;
      color: white;
      font-size: 18px;
      text-decoration: none;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    svg {
      fill: none;
      stroke: white;
      margin-right: 10px;
    }
    @media ($desktop-s) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

}

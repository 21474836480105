.health {
  position: relative;
  padding-top: 54px;
  padding-bottom: 102px;
  overflow: hidden;
  @media ($desktop-s) {
    padding-bottom: 0;
  }
  @media ($tablet) {
    padding-top: 43px;
    padding-bottom: 93px;
  }
  @media ($mobile) {
    padding-top: 53px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }
  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    min-height: 1200px;
    padding-top: 45px;
    padding-left: 87px;
    padding-right: 42px;
    z-index: 2;
    @media ($desktop-s) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media ($tablet) {
      padding-right: 18px;
      min-height: 0;
      min-height: 760px;
    }
    @media ($mobile) {
      flex-direction: column;
      justify-content: flex-start;
      min-height: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0;
    }
  }
  &__woman {
    position: absolute;
    width: 51%;
    top: 26px;
    max-width: 734px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    margin: auto;
    @media ($tablet) {
      width: 66%;
      top: 133px;
      max-width: 542px;
    }
    @media ($mobile) {
      display: none;
      width: 155%;
      position: relative;
      top: -30px;
      left:0;
      order: 5;
      margin: 0 auto;
      max-width: 630px;
    }
    @media (max-width: 590px) {
      left: -7%;
    }
    @media (max-width: 500px) {
      left: -20%;
    }
    @media (max-width: 400px) {
      left: -28%;
    }
  }
  &__title {
    margin-bottom: 50px;
    br {
      display: none;
      @media ($tablet) {
        display: inline-block;
      }
    }
    @media ($tablet) {
      margin-bottom: 36px;
    }
    @media ($mobile) {
      line-height: 26px;
      margin-bottom: 40px;
    }
  }

  &__list {
    padding-left: 52px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ($tablet) {
      justify-content: flex-start;
    }
    @media ($mobile) {
      min-height: 0;
    }
    &--left {
      padding-bottom: 323px;
      @media ($tablet) {
        padding-bottom: 0;
      }
    }
    &--right {
      padding-top: 110px;
      padding-bottom: 295px;
      @media ($tablet) {
        padding-top: 60px;
        padding-bottom: 0;
      }
      @media ($mobile) {
        padding-top: 0;
      }
    }
  }
  &__item {
    width: 320px;
    position: relative;
    line-height: normal;
    font-size: 16px;
    line-height: 21px;
    color: #3F4447;
    cursor: pointer;
    &.is-open {
      .js-more-link {
        display: none;
      }
    }
    span {
      display: none;
    }
    a {
      color: $color-blue;
      text-decoration: underline;
    }
    &--330 {
      width: 330px;
    }
    &--340 {
      width: 340px;
    }
    &--355 {
      width: 355px;
    }
    @media ($desktop-s) {
      width: 280px;
      &--330,
      &--340,
      &--355
       {
        width: 280px;
      }
    }
    @media ($tablet) {
      line-height: normal;
      font-size: 14px;
      width: 180px;
      margin-bottom: 36px;
      &--330,
      &--340,
      &--355
       {
        width: 215px;
      }
    }
    @media ($mobile) {
      width: 100%;
      margin-bottom: 39px;
      &--330,
      &--340,
      &--355
       {
        width: 100%;
      }
    }
    p {
      margin-top: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:before {
      content: "";
      position: absolute;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0idXJsKCNwYWludDBfcmFkaWFsKSIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IiMwMEMzRUMiLz4KPGxpbmUgeDE9IjIwLjUiIHkxPSIxNCIgeDI9IjIwLjUiIHkyPSIyNyIgc3Ryb2tlPSIjMDBDM0VDIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWwiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAuNSAyMC41KSByb3RhdGUoOTApIHNjYWxlKDE0LjUpIj4KPHN0b3Agc3RvcC1jb2xvcj0id2hpdGUiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRUFFQUVBIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==');
      width: 40px;
      height: 40px;
      top: 0;
      left: -52px;
      background-size: cover;
      transition: all 0.2s;
    }
    &:hover {
      &:before {
        transition: all 0.2s;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0iIzAwQzNFQyIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IndoaXRlIi8+CjxsaW5lIHgxPSIyMC41IiB5MT0iMTQiIHgyPSIyMC41IiB5Mj0iMjciIHN0cm9rZT0id2hpdGUiLz4KPC9zdmc+Cg==');
        transform: rotate(180deg);
      }
      @media ($mobile) {
        &:before {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0idXJsKCNwYWludDBfcmFkaWFsKSIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IiMwMEMzRUMiLz4KPGxpbmUgeDE9IjIwLjUiIHkxPSIxNCIgeDI9IjIwLjUiIHkyPSIyNyIgc3Ryb2tlPSIjMDBDM0VDIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWwiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAuNSAyMC41KSByb3RhdGUoOTApIHNjYWxlKDE0LjUpIj4KPHN0b3Agc3RvcC1jb2xvcj0id2hpdGUiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRUFFQUVBIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==');
          transform: translateY(0);
        }
      }
    }
    &.is-open {
      &:before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0iIzAwQzNFQyIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IndoaXRlIi8+Cjwvc3ZnPgo=');
      }
      &:hover {
        &:before {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0iIzAwQzNFQyIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IndoaXRlIi8+Cjwvc3ZnPgo=');
          transform: rotate(0deg);
        }

      }
    }

  }


}

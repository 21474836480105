.soon-card {
  width: 380px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  overflow: hidden;
  @media ($desktop-s) {
    width: 300px;
  }
  @media ($tablet)  {
    width: 228px;
  }
  @media ($mobile) {
    width: 100%;
    margin-bottom: 21px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__top {
    padding-left: 15px;
    padding-right: 13px;
    padding-top: 20px;
    padding-bottom: 15px;
    min-height: 155px;
    @media ($desktop-s) {
      min-height: 206px;
    }
    @media ($tablet) {
      padding-left: 7px;
      padding-top: 7px;
      min-height: 132px;
    }
    @media ($mobile) {
      min-height: 0;
      padding-bottom: 17px;
    }
  }

  &__image-wrap {
    align-items: center;
    display: flex;
    height: 375px;    
    justify-content: center;

    &_soft {
      background: #00a399;
    }

    &_sport {
      background: #00abc1;
    }

    &_foot {
      background: #008fb0;
    }
  }

  &__image {
    object-fit: contain;
    height: 90%;
    width: 90%;
  }

  &__text {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 15px;
    padding-bottom: 30px;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    p {
      margin-top: 0;
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;

      }
    }
    @media ($tablet) {
      line-height: 17px;
      font-size: 12px;
      padding-left: 7px;
      padding-top: 4px;
      padding-right: 7px;
      padding-bottom: 27px;
    }
    @media ($mobile) {
      padding-top: 12px;
      padding-bottom: 18px;
    }
  }
}

.slick {
  &-prev,
  &-next {
    font-size: 0;
    background: none;
    background-size: contain;
    border: 0;
    width: 28px;
    height: 54px;
    padding: 0;
    flex-shrink: 0;
    margin-bottom: 62px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media ($mobile) {
      height: 27px;
      margin-bottom: 0;
      position: static;
      transform: none;
      width: 14px;
    }
  }
  &-prev {
    left: -63px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCAyOSA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4IDFMMiAyOC44OTRMMjggNTUiIHN0cm9rZT0iIzAwQzNFQyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=');
    @media (max-width: 1370px) {
      left: -15px;
    }
    @media ($tablet) {
      z-index: 2;
      left: 10px;
    }
  }
  &-next {
    right: -63px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCAyOSA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuOTk5OTk5IDFMMjcgMjguODk0TDAuOTk5OTk5IDU1IiBzdHJva2U9IiMwMEMzRUMiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K');
    @media (max-width: 1370px) {
      right: -15px;
    }
    @media ($tablet) {
      z-index: 2;
      right: 10px;
    }
  }
  &-track {
    display: flex;
  }
  &-list {
    margin: 0 -10px;
    padding-bottom: 15px;
  }
  &-slide {
    height: auto;
    outline: none;
  }
  &-initialized .slick-slide {
    display: flex;
  }


}

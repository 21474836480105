.reviews {
  background: linear-gradient(180deg, #F9F9F9 0%, #FFFFFF 100%);
  padding-top: 64px;
  padding-bottom: 65px;
  @media ($tablet) {
    padding-top: 55px;
    padding-bottom: 38px;
  }
  @media ($mobile) {
    padding-top: 68px;
    padding-bottom: 36px;
  }
  &__container {
    max-width: 1236px;
    padding-left: 0;
    padding-right: 0;
    @media ($desktop-md) {
      max-width: 1200px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media ($desktop-s) {
      max-width: 1000px;
      padding-left: 15px;
      padding-right: 15px;
    }
     @media ($tablet) {
      max-width: 720px;
      padding-left: 0;
      padding-right: 0;
    }
    @media ($mobile) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__title {
    margin-bottom: 57px;
    @media ($tablet) {
      margin-bottom: 54px;
    }
    @media ($mobile) {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 39px;
    }
  }
  &__slider {
    position: relative;
  }
  &__slider-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__slider-nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.faq {
  padding-top: 112px;
  background: linear-gradient(7.28deg, #FFFFFF 33.54%, #F4F4F4 95.85%);
  @media ($tablet) {
    padding-top: 71px;
  }
  @media ($mobile) {
    padding-top: 54px;
  }
  &__title {
    margin-bottom: 51px;
    @media ($tablet) {
      margin-bottom: 33px;
    }
    @media ($mobile) {
      padding-left: 15px;
      padding-right: 15px;
      line-height: 26px;
      margin-bottom: 2px;
    }
  }
  &__container {
    max-width: 1240px;
  }
  &__item-title {
    position: relative;
    line-height: normal;
    font-weight: normal;
    font-size: 24px;
    color: #3F4447;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-blue;
    padding-top: 23px;
    padding-bottom: 33px;
    padding-right: 60px;
    user-select: none;
    @media ($tablet) {
      line-height: normal;
      font-size: 20px;
      padding-bottom: 20px;
      br {
        display: none;
      }
    }
    @media ($mobile) {
      font-size: 16px;
      padding-bottom: 14px;
      br {
        display: inline-block;
      }
    }

    &:before {
      content: "";
      position: absolute;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0idXJsKCNwYWludDBfcmFkaWFsKSIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IiMwMEMzRUMiLz4KPGxpbmUgeDE9IjIwLjUiIHkxPSIxNCIgeDI9IjIwLjUiIHkyPSIyNyIgc3Ryb2tlPSIjMDBDM0VDIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWwiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAuNSAyMC41KSByb3RhdGUoOTApIHNjYWxlKDE0LjUpIj4KPHN0b3Agc3RvcC1jb2xvcj0id2hpdGUiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRUFFQUVBIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==');
      width: 40px;
      height: 40px;
      top: 50%;
      right: 0;
      background-size: cover;
      transform: translateY(-20px);
      transition: all 0.2s;
    }
    &:hover {
      &:before {
        transition: all 0.2s;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0iIzAwQzNFQyIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IndoaXRlIi8+CjxsaW5lIHgxPSIyMC41IiB5MT0iMTQiIHgyPSIyMC41IiB5Mj0iMjciIHN0cm9rZT0id2hpdGUiLz4KPC9zdmc+Cg==');
        transform: rotate(180deg) translateY(20px);
      }
      @media ($mobile) {
        &:before {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0idXJsKCNwYWludDBfcmFkaWFsKSIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IiMwMEMzRUMiLz4KPGxpbmUgeDE9IjIwLjUiIHkxPSIxNCIgeDI9IjIwLjUiIHkyPSIyNyIgc3Ryb2tlPSIjMDBDM0VDIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWwiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAuNSAyMC41KSByb3RhdGUoOTApIHNjYWxlKDE0LjUpIj4KPHN0b3Agc3RvcC1jb2xvcj0id2hpdGUiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRUFFQUVBIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==');
          transform: translateY(-20px);
        }
      }
    }
    &.is-open {
      font-weight: bold;
      border-bottom: none;
      &:before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAuNSIgY3k9IjIwLjUiIHI9IjE5LjUiIGZpbGw9IiMwMEMzRUMiIGZpbGwtb3BhY2l0eT0iMC4wNyIgc3Ryb2tlPSIjMDBDM0VDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjEgNCIvPgo8Y2lyY2xlIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMTQuNSIgZmlsbD0iIzAwQzNFQyIvPgo8bGluZSB4MT0iMTQiIHkxPSIyMC41IiB4Mj0iMjciIHkyPSIyMC41IiBzdHJva2U9IndoaXRlIi8+Cjwvc3ZnPgo=');
      }
    }
  }
  &__item {
    position: relative;
    &:last-child {
      .faq__item-title {
        border-bottom: 0;
        padding-bottom: 30px;
      }
    }
  }
  &__item-content {
    display: none;
    background-color: #fff;
    border-right-top-radius: 8px;
    border-right-bottom-radius: 8px;
    border-left: 2px solid $color-blue;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 15px;
    padding-bottom: 15px;
    font-weight: normal;
    line-height: 26px;
    font-size: 18px;
    color: #3F4447;
    p {
      margin-top: 0;
      margin-bottom: 23px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      list-style: disc;
      padding-left: 25px;
    }
    @media ($tablet) {
      line-height: 20px;
      font-size: 14px;
      margin-bottom: 20px;
      p {
        margin-bottom: 20px;
      }
    }
    @media ($mobile) {
      line-height: 17px;
      font-size: 12px;
      padding-left: 12px;
      padding-right: 5px;
    }

  }
}


.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 500px;
  background: url("../images/footer-bg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 15px;
  @media (min-width: 1600px) {
    background-size: cover;
  }
  @media ($tablet) {
    background-size: 172%;
    background-position: center center;
  }
  @media ($mobile) {
    background-size: 400%;
    background-position: center 25px;
    height: 500px;
    padding-bottom: 0;
  }
  &__logoinfo {
    display: flex;
    @media ($mobile) {
      padding-left: 15px;
      align-self: center;
      order: 3;
    }
  }
  &__container {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1240px;
    @media ($tablet) {
      position: relative;
      padding-left: 22px;
      padding-right: 22px;
    }
    @media ($mobile) {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.82) 59.12%, #FFFFFF 100%);
      padding-bottom: 10px;
      padding-top: 100px;
    }
  }
  &__left {
    display: flex;
    flex-grow: 1;
    svg {
      fill: #009BA7;
    }
    @media ($tablet) {
      justify-content: space-between;
    }
    @media ($mobile) {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
  &__contacts {
    flex-shrink: 0;
    margin-left: 13.2%;
    margin-bottom: -6px;
    a {
      display: block;
      font-size: 18px;
      color: #3F4447;
      text-decoration: none;
    }

    .footer__socials {
      color: $color-blue;
      font-size: 16px;
      text-decoration: underline;
    }

    @media ($tablet) {
      align-self: flex-start;
      margin-top: -7px;
      a {
        font-size: 16px;
        text-align: right;
        margin-bottom: 7px;
      }
    }
    @media ($mobile) {
      align-self: center;
      text-align: center;
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 0;
      a {
       text-align: center;
      }
      order: 1;
    }
  }
  &__ibrush {
    font-size: 16px;
    text-align: right;
    color: #3F4447;
    align-self: flex-end;
    a {
      color: $color-blue;

      &:hover {
        color: darken($color-blue, 5%);
      }
    }
    &--mob {
      display: none;
    }
    @media ($tablet) {
      position: absolute;
      right: 0;
      width: 100%;
      max-width: 1240px;
      padding-left: 22px;
      padding-right: 22px;
    }
    @media ($mobile) {
      display: none;
      position: relative;
      bottom: 0;
      right: 0;
      order: 2;
      padding: 0;
      text-align: center;
      margin-bottom: 40px;
      &--mob {
        display: block;
      }
    }
  }
  &__address {
    line-height: normal;
    font-size: 16px;
    margin-left: 19px;
    p {
      margin-top: 0;
      margin-bottom: 17px;
      @media ($tablet) {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media ($tablet) {
      width: 180px;
      font-size: 14px;
      br {
        display: none;
      }
    }
    @media ($mobile) {
      margin-left: 14px;
    }
  }
  &__manufactured {
    line-height: normal;
    font-size: 13px;
    padding-top: 15px;
    width: 100%;
    @media ($tablet) {
      font-size: 12px;
    }
    @media ($mobile) {
      padding-top: 10px;
      max-width: 300px;
      margin: 0 auto;
    }
    p {
      margin:0;
    }
  }

  &__socials {
    color: $color-blue;

    &:hover {
      color: darken($color-blue, 5%);
    }

    &-icon {
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
    }
  }
}

.favorite {
  padding-top: 134px;
  padding-bottom: 88px;
  background-color: #F9F9F9;
  @media ($tablet) {
    padding-top: 73px;
    padding-bottom: 28px;
  }
  @media ($mobile) {
    padding-top: 43px;
  }
  &__container {
    display: flex;
    max-width: 1180px;
    padding: 0;
    @media ($tablet) {
      padding-left: 22px;
      padding-right: 22px;
      align-items: flex-start;
    }
    @media ($mobile) {
      padding-left: 15px;
      padding-right: 15px;
    }

  }
  &__title {
    margin-bottom: 45px;
    @media ($tablet) {
      margin-bottom: 36px;
    }
    @media ($mobile) {
      margin-bottom: 35px;
    }
  }
  &__desc {
    @media ($mobile) {
      padding-top: 2px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 60px;
    padding-top: 24px;
    padding-left: 20px;
    padding-bottom: 21px;
    width: 50%;
    @media ($tablet) {
      padding-left: 0;
      padding-top: 0;
      margin-right: 30px;
    }
    @media ($mobile) {
      width: 100%;
      margin-right: 0;
      padding-bottom: 0;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(172.84deg, #FFFFFF 1.24%, #F7F7F7 97.91%);
    width: 590px;
    flex-shrink: 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding-top: 60px;
    padding-bottom: 20px;
    @media ($tablet) {
      width: 344px;
      padding-top: 28px;
      img {
        width: 70%;
      }
    }
    @media ($mobile) {
      display: none;
    }
    &--mob {
      display: none;
      @media ($mobile) {
        display: flex;
        width: 100%;
        padding-top: 23px;
        padding-bottom: 16px;
      }
    }
  }

  &__text-cont {
    padding-bottom: 34px;
    line-height: 23px;
    font-size: 16px;
    color: #3F4447;
    p {
      margin-top: 0;
      margin-bottom: 23px;
      &:last-child {
        margin-bottom: 0;
      }
      @media ($tablet) {
        margin-bottom: 18px;
      }
    }
    @media ($tablet) {
      padding-top: 16px;
      padding-bottom: 20px;
      line-height: 20px;
      font-size: 14px;
    }
  }

}

.top {
  left: 0;
  padding-bottom: 12px;
  padding-top: 12px;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color 0.3s;
  z-index: 98;

  &.is-active {
    background: #fff;
  }

  @media ($desktop-s) {
    padding-left: 24px;
    padding-right: 22px;
  }

  @media ($mobile) {
    padding-left: 15px;
    padding-right: 14px;
  }

  &__inner {
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: space-between;
  }

  &__logo {
    margin-right: 27px;

    svg {
      fill: #009BA7;
    }

    @media ($desktop-s) {
      margin-right: 0;
    }
  }

  &__mob-burger {
    display: none;
    border: none;
    background: none;
    padding: 0;
    position: relative;
    z-index: 2;

    svg{
      fill: #4CA5BC;
    }

    @media ($desktop-s) {
      display: block;
    }
  }

  &__block {
    display: flex;
    align-items: center;

    &--right {
      margin-left: 77px;

      @media ($desktop-s) {
        margin-left: -18px;
      }

      @media ($mobile) {
        display: none;
      }
    }
  }

  &__nav {
    flex-shrink: 0;
  }

  &__icon-link {
    display: flex;
    align-items: center;
    font-size: 18px;
    text-align: right;
    text-decoration: none;
    color: #009BA7;
    margin-right: 26px;
    flex-shrink: 0;

    &:hover {
      color: darken(#009BA7, 5%);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__instagram-icon {
      fill: #009ba7;
      margin-right: 15px;
  }

  &__email-icon {
    margin-right: 15px;
    fill: none;
    stroke: #009ba7;
  }
}

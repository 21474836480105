.header {
  padding-top: 73px;
  height: 698px;
  margin-bottom: 180px;
  background: url(../images/header-bg.jpg) no-repeat;
  background-size: cover;

  @media ($desktop-md) {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  @media ($desktop-s) {
    height: auto;
    margin-bottom: 45px;
    padding-left: 24px;
    padding-right: 22px;
  }
  @media ($mobile) {
    padding-left: 15px;
    padding-right: 14px;
    margin-bottom: 0;
  }
  &__container {
    margin: 0 auto;
    max-width: 1280px;
  }

  &__content {
    display: flex;
    align-items: center;
    position: relative;
    @media ($tablet) {
      padding-top: 45px;
      align-items: flex-start;
      flex-direction: column;
    }
    @media ($mobile) {
      padding-top: 34px;
    }
  }
  &__titles {
    font-family: 'Din Alternate';
    text-transform: uppercase;
    color: #009BA7;
    text-align: right;
    width: 29%;
    flex-shrink: 0;
    padding-bottom: 57px;
    @media ($desktop-s) {
      width: 33%;
    }
    @media ($tablet) {
      width: 50%;
      text-align: left;
      padding-bottom: 20px;
    }
    @media ($mobile) {
      width: 55%;
      padding-bottom: 13px;
    }
  }
  &__title1 {
    line-height: 65px;
    font-size: 62px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 10px;
    @media ($desktop-md) {
      font-size: 58px;
    }
    @media ($desktop-s) {
      font-size: 50px;
      line-height: 55px;
    }
    @media ($tablet) {
      line-height: 38px;
      font-size: 36px;
      margin-bottom: 4px;
    }
    @media ($mobile) {
      line-height: 17px;
      font-size: 16px;
      margin-bottom: 9px;
    }
  }
  &__title2 {
    line-height: 39px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 28px;
    font-weight: normal;
    @media ($desktop-md) {
      font-size: 26px;
    }
    @media ($desktop-s) {
      font-size: 22px;
      line-height: 26px;
    }
    @media ($tablet) {
      line-height: 34px;
      font-size: 24px;
      padding-right: 8%;
    }
    @media ($mobile) {
      line-height: 17px;
      font-size: 12px;
    }
  }
  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-left: -8.8%;
    top: 51px;
    flex-shrink: 0;
    svg {
      fill: none;
      position: relative;
      top: -31px;
      left: 22px;
      @media ($desktop-s){
        display: none;
      }

    }
    @media ($desktop-s){
      width: 50%;
      top: 0;
    }
    @media ($tablet) {
      position: absolute;
      bottom: -122px;
      right: -17px;
      width: 65%;
      max-width: 530px;
      justify-content: flex-end;
      margin-left: 0;
    }
    @media ($mobile) {
      height: 100%;
      right: -7px;
      width: 71%;
      top: 12px;
      justify-content: flex-start;
      max-width: 300px;
    }
  }
  &__info {
    margin-left: -5%;
    max-width: 307px;
    padding-bottom: 21px;
    @media ($tablet) {
      margin-left: 0;
    }
  }
  &__list {
    padding-left: 6px;
    @media ($tablet) {
      padding-left: 0;
    }
    @media ($mobile) {
      width: 50%;
    }
  }
  &__item {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.4643 2.60377L10.3214 1L4.53571 2.60377M15.4643 2.60377L19 5.81132M15.4643 2.60377L10.3214 3.56604M19 5.81132L10.3214 18M19 5.81132L14.5 9.01887M10.3214 18L1 5.81132M10.3214 18L5.82143 9.01887M10.3214 18V9.98113M10.3214 18L14.5 9.01887M1 5.81132L4.53571 2.60377M1 5.81132L5.82143 9.01887M4.53571 2.60377L10.3214 3.56604M5.82143 9.01887L10.3214 9.98113M5.82143 9.01887L10.3214 3.56604M10.3214 9.98113L14.5 9.01887M14.5 9.01887L10.3214 3.56604' stroke='%23009BA7'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0 3px;
    padding-left: 26px;
    font-size: 18px;
    margin-bottom: 19px;
    line-height: 23px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      text-decoration: none;
      color: black;

    }
    @media ($mobile) {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 13px;
      padding-left: 16px;
      background-size: 7%;
    }
  }
  &__btn {
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 54px;
    height: 54px;
    padding-left: 20px;
    padding-right: 10px;
    width: 100%;
    background-color: $color-blue;
    color: white;
    text-decoration: none;
    font-size: 18px;
    line-height: 1;
    border-radius: 5px;
    &:hover {
      background-color: #00A3C5;
    }
    svg {
      fill: none;
      margin-left: 18px;
    }
    @media ($tablet) {
      margin-top: 21px;
    }
    @media ($mobile) {
      max-width: 290px;
      position: relative;
    }
  }


}

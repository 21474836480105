.about {
  position: relative;
  padding-top: 45px;
  padding-bottom: 42px;
  svg {
    fill: none;
  }
  @media ($mobile) {
    padding-top: 33px;
    padding-bottom: 0;
  }
  &__container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    @media ($tablet) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media ($mobile) {
      padding-left: 15px;
      padding-right: 15px;
      flex-direction: column;
    }
  }
  &__deo {
    position: absolute;
    width: 31%;
    top: 180px;
    max-width: 444px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    margin: auto;
    @media ($tablet) {
      width: 38%;
      top: 28px;
    }
    @media ($mobile) {
      display: none;
    }
  }
  &__mob-deo {
    width: 100%;
    display: none;
    width: 79%;
    margin: 0 auto;
    margin-top: 26px;
    margin-bottom: 22px;
    max-width: 340px;
    @media ($mobile) {
      display: block;
    }
  }
  &__title {
    margin-bottom: 84px;
    @media ($tablet) {
      margin-bottom: 67px;
    }
    @media ($mobile) {
      margin-bottom: 56px;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media ($mobile) {
      align-items: center;
    }

  }
  &__right {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    @media ($tablet) {
      padding-top: 145px;
      .about__block {
        width: 237px;
        padding-top: 21px;
        padding-left: 12px;
        svg {
          margin-bottom: 10px;
        }
      }
    }
    @media ($mobile) {
      padding-top: 0;
      .about__block {
        width: 100%;
        padding-left: 14px;
        padding-top: 14px;
      }
    }
  }

  &__text-cont {
    font-weight: normal;
    line-height: 23px;
    font-size: 16px;
    color: #3F4447;
    padding-right: 20px;
    width: 100%;
    @media ($tablet) {
      font-size: 14px;
      line-height: 20px;
    }
    @media ($mobile) {
      padding-right: 0;
    }
  }
  &__text {
    margin-top: 0;
    margin-bottom: 22px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 426px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 26px;
    padding-bottom: 19px;
    svg {
      margin-bottom: 23px;
    }
    @media ($desktop-s) {
      width: 350px;
    }
    @media ($tablet) {
      width: 250px;
      padding-top: 13px;
      padding-right: 5px;
      padding-bottom: 37px;
      svg {
        margin-bottom: 15px;
      }
    }
    @media ($mobile) {
      width: 100%;
      padding-left: 12px;
      padding-bottom: 16px;
    }
  }

  &__arrow-right {
    margin-right: -51px;
    @media ($desktop-s) {
      display: none;
    }
  }
  &__arrow-left {
    transform: rotate(188deg);
    margin-left: -58px;
    margin-bottom: 22px;
    @media ($desktop-s) {
      display: none;
    }
  }
}

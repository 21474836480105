.popup {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  height: 100vh;

  &__inner {
    margin: auto;
    width: 916px;

    @media ($tablet) {
      display: block;
      padding-top: 50px;
      padding-bottom: 50px;
      width: 700px;
    }
    @media ($mobile) {
      width: 100%;
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__close {
    position: absolute;
    top: -5px;
    right: -63px;
    cursor: pointer;
    @media ($tablet) {
      top: 10px;
      right: 10px;
    }
    @media ($mobile) {
      top: 0;
      right: 0;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  &__image {
    position: relative;
    flex-shrink: 0;
    z-index: 0;
    @media ($tablet) {
      width: 30%;
    }
    @media ($mobile) {
      display: none;
    }
  }
  &__content {
    padding-left: 130px;
    margin-left: -100px;
    padding-top: 41px;
    padding-right: 40px;
    padding-bottom: 62px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    line-height: 23px;
    font-size: 16px;
    color: #3F4447;
    @media ($mobile) {
      padding: 20px;
      margin-left: 0;
      line-height: 20px;
      font-size: 14px;
      box-shadow: none;
      border: none;
    }
  }
  &__title {
    margin: 0;
    font-family: 'Din Alternate';
    line-height: 1;
    font-size: 24px;
    font-weight: normal;
    text-transform: uppercase;
    color: #009BA7;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }

  &__text {
    padding-top: 21px;
    p {
      margin-top: 0;
      margin-bottom: 23px;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }

}

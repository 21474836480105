.popup-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(255,255,255,0.87);
  display: none;
  top: 0;
  left: 0;
  &.--transparent {
    background: transparent;
  }
  @media ($mobile) {
    background-color: #fff;
  }
}
